import Vue from 'vue'

export default class AppAlerts extends Vue {

    //Alert z potwierdzeniem
    openAlertConfirmation(argContent){

        const h = this.$createElement
              
        const messageVNode = h('div', { class: ['foobar'] }, [

        h('p', { class: ['text-center text-primary'] }, [h('b-icon', {props: { icon: "question-circle", "font-scale": "6",}})]),

        //h('p', { class: ['text-center font-weight-500 text-lg mb-1'] }, [h('strong', argTitle),]),

        //h('p', { class: ['text-center text-base'] }, [h('b', argContent),]),
        //h('p', { class: ['text-center text-base'] }, [argContent]),
        h('p', { class: ['text-center text-base'], domProps: { innerHTML: argContent } })

        ]);

        //this.$createElement('p', { domProps: { innerHTML: html } })

        return this.$bvModal.msgBoxConfirm([messageVNode], {
                                                                size: 'sm',
                                                                buttonSize: 'sm',

                                                                okVariant: 'primary btn-ok',
                                                                okTitle: 'Ja',

                                                                cancelTitle: 'Nee',
                                                                cancelVariant: 'light btn-cancel',
                                                                
                                                                footerClass: 'p-2 app-confirmation-modal',
                                                                hideHeaderClose: false,
                                                                centered: true
                                                            })
                                                            .then(value => {
                                                                return value;
                                                            })
                                                            .catch(err => {
                                                                // An error occurred
                                                            })

    }



    //Alert warning
    openAlertWarning(argContent){

        const h = this.$createElement
              
        const messageVNode = h('div', { class: ['foobar'] }, [

        h('p', { class: ['text-center text-yellow'] }, [h('b-icon', {props: { icon: "exclamation-triangle", "font-scale": "6",}})]),

        //h('p', { class: ['text-center font-weight-500 text-lg mb-1'] }, [h('strong', argTitle),]),

        h('p', { class: ['text-center text-base'] }, [h('strong', argContent),]),

        ]);

        return this.$bvModal.msgBoxOk([messageVNode], {
                                                                size: 'sm',
                                                                buttonSize: 'sm',

                                                                okVariant: 'primary btn-ok',
                                                                okTitle: 'OK',
 
                                                                footerClass: 'p-2 app-confirmation-modal',
                                                                hideHeaderClose: false,
                                                                centered: true
                                                            })
                                                            .then(value => {
                                                                return value;
                                                            })
                                                            .catch(err => {
                                                                // An error occurred
                                                            })


    }


    //Alert info
    openAlertInfo(argContent){

        const h = this.$createElement
              
        const messageVNode = h('div', { class: ['foobar'] }, [

        h('p', { class: ['text-center text-secondary'] }, [h('b-icon', {props: { icon: "info-circle", "font-scale": "6",}})]),

        //h('p', { class: ['text-center font-weight-500 text-lg mb-1'] }, [h('strong', argTitle),]),

        h('p', { class: ['text-center text-base'] }, [h('strong', argContent),]),

        ]);

        return this.$bvModal.msgBoxOk([messageVNode], {
                                                                size: 'sm',
                                                                buttonSize: 'sm',

                                                                okVariant: 'secondary',
                                                                okTitle: 'OK',
                                                                
                                                                footerClass: 'p-2 app-confirmation-modal',
                                                                hideHeaderClose: false,
                                                                centered: true
                                                            })
                                                            .then(value => {
                                                                return value;
                                                            })
                                                            .catch(err => {
                                                                // An error occurred
                                                            })

    }
}


